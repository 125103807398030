import React, { CSSProperties } from "react";
import { IconType } from "react-icons/lib";
import "./CardTile.css";

interface PropsCardTile {
    Icon?: IconType;
    active: boolean;
    fnClick: (...args : any[]) => any;
    style?: CSSProperties;
    hidecheckbox?: boolean;
}

const CardTile : React.FC<PropsCardTile> = ({Icon, children, active, fnClick, style, hidecheckbox = false}) => {
    return (
        <div style={style} className={`card-tile ${active ? "active" : ""}`} onClick={fnClick}>
            {Icon && <Icon style={{fontSize: 32}} />}
            <span className="card-tile-text">{children}</span>
            {!hidecheckbox && <span className="tile-radio"></span>}
        </div>
    )
}

export default CardTile;