import React, { CSSProperties } from "react";
import "./RangeSlider.css";

interface PropsRangeSlider {
    min?: number;
    max?: number;
    value: number;
    step?:  number;
    onChange: (e : React.ChangeEvent<HTMLInputElement>) => void;
    tooltipLabel?: (v: number) => string;
    style?: CSSProperties;
    className?: string;
    size?: "lg" | "sm" ;
    stripped?: boolean;
    stepstripped? : boolean;
}

const RangeSlider : React.FC<PropsRangeSlider> = ({min=0, max=100, value, step=1, onChange, style, className, size = "lg", tooltipLabel, stripped = false, stepstripped = false}) => {

    
    return (
        <div className={className} style={{position: "relative"}}>
            <input type="range" max={max} min={min} value={value} step={step} onChange={onChange} className={`custom-range`} />
            {stripped && !stepstripped && <svg className="range__ticks" role="presentation" width="100%" height="15" xmlns="http://www.w3.org/2000/svg">
                {/* <rect className="range__tick" x="0%" y="3" width="1" height="10"></rect> */}
                <rect className="range__tick" x="20%" y="0" width="2" height="15"></rect>
                <rect className="range__tick" x="40%" y="0" width="2" height="15"></rect>
                <rect className="range__tick" x="60%" y="0" width="2" height="15"></rect>
                <rect className="range__tick" x="80%" y="0" width="2" height="15"></rect>
                {/* <rect className="range__tick" x="100%" y="3" width="1" height="10"></rect> */}
            </svg>}
            {stripped && stepstripped &&
                <svg className="range__ticks" role="presentation" width="100%" height="15" xmlns="http://www.w3.org/2000/svg">
                    <rect className="range__tick" x={`26%`} y="0" width="2" height="15"></rect>
                    <rect className="range__tick" x={`49.8%`} y="0" width="2" height="15"></rect>
                    <rect className="range__tick" x={`73.8%`} y="0" width="2" height="15"></rect>
                </svg>
            }
        </div>
    )
}

export default RangeSlider;