import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Info from "../../../components/Info";
import Back from "../../../components/Back";
import Subtitle from "../../../components/Subtitle";
import DatePicker from "../../../components/DatePicker";
import Cardinfo from "../../../components/Cardinfo";
import InputText from "../../../components/InputText";
import { onlyNumbers } from "../../../utils/functions";

const QUESTION = "Quelle est votre année de naissance ?";
const INFO = "Cette information nous est utile pour vous indiquer les dates de retrait possibles";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep4 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [bd, setBd] = useState (data.datenaissance);
    const [datepfocus, setDatepfocus] = useState (false);

    const reloads = useRef (0);
    useEffect (() => {
        reloads.current++;
    }, [bd]);

    const _onPress = () => {
        validateStep ({...data, datenaissance: bd})
    }

    const isDateValid = () => {
        if (!bd)
            return;
        if (bd.length !== 10)
            return;
        const date = new Date (bd);
        return date instanceof Date && !isNaN (date.getTime ());
    }

    const getAgeFromDate = () => {
        if (!bd)
            return 0;
        const today = new Date ();
        const birthDate = new Date (bd);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const inpudatevalid = (s: string) => s.length === 4 && onlyNumbers (s) && (new Date ().getFullYear () - parseInt (s, 10) >= 18) && (new Date ().getFullYear () - parseInt (s, 10) <= 80)

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            <Cardinfo style={{marginBottom: 40}} message={INFO+"."} />
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            {isDateValid () && getAgeFromDate () > 0 &&
                <Subtitle>J'ai <b>{getAgeFromDate ()} {getAgeFromDate () > 1 ? "ans" : "an"}</b>.</Subtitle>
            }
            {/* <DatePicker
                autoFocus={!(isDateValid () && getAgeFromDate () >= 18 && getAgeFromDate () <= 80)}
                setDatepfocus={setDatepfocus}
                birthdate={bd}
                fnChange={setBd}
                className={isDateValid () && getAgeFromDate () >= 18 && getAgeFromDate () <= 80 ? "valid" : ""}
                error={reloads.current > 1 && (!isDateValid () || getAgeFromDate () < 18 || getAgeFromDate () > 80 || isNaN (getAgeFromDate ())) && (bd.length === 10 || !datepfocus) ? "Vous ne remplissez pas les prérequis" : undefined}
            /> */}
            <InputText
                id="birthyear"
                name="birthyear"
                placeholder="YYYY"
                aria-label="Année de naissance"
                value={bd}
                onChange={(e : any) => {
                    if (onlyNumbers (e.target.value) && e.target.value.length <= 4)
                        setBd (e.target.value);
                }}
                valid={inpudatevalid (bd)}
                className={inpudatevalid (bd) ? "valid" : ""}
                error={reloads.current > 1 && !inpudatevalid (bd) && !datepfocus ? "Vous ne remplissez pas les prérequis" : undefined}
                onFocus={() => setDatepfocus (true)}
                onBlur={() => setDatepfocus (false)}
            />
            <Button style={{marginTop: 30}} fnClick={_onPress} disabled={!inpudatevalid (bd)} >Continuer</Button>
        </motion.div>
    )
}

export default PlacementStep4;