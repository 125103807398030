import React, { useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Tile from "../../../components/Tile";
import Info from "../../../components/Info";
import Back from "../../../components/Back";
import Cardinfo from "../../../components/Cardinfo";

const QUESTION = "Quel est votre régime d'assurance maladie ?";
const INFO = "Cette information nous est utile pour vous informer sur l'aspect fiscal du capital de prévoyance";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep11 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [tileSelected, setTileSelected] = useState (data.regimeassurance);

    const _onPress = () => {
        validateStep ({...data, regimeassurance: tileSelected})
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Tile 
                fnClick={() => setTileSelected ({id: 1, title: "Affilié à LAMal"})} 
                active={tileSelected.id === 1}
                style={{marginBottom: 20}}>
            Je suis affilié(e) à la <b>LAMal</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 2, title: "Affilié à la CMU (CNTFS)"})} 
                active={tileSelected.id === 2}
                style={{marginBottom: 20}}>
            Je suis affilié(e) à la <b>CMU (CNTFS)</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 3, title: "Affilié à un autre régime d'assurance maladie"})} 
                active={tileSelected.id === 3}
                style={{marginBottom: 20}}>
            Je suis affilié(e) à un autre régime d'assurance maladie</Tile>
            <Button fnClick={_onPress} disabled={tileSelected.id === 0} style={{marginTop: 30}} >Continuer</Button>
            <Cardinfo style={{marginTop: 40}} message={INFO} />
        </motion.div>
    )
}

export default PlacementStep11;