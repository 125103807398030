import React, { CSSProperties, useEffect } from "react";
import Button from "../../../components/Button";
import Title from "../../../components/Title";
import { ILeadFirst } from "../../../interfaces/lead";

interface PropsRechercheEnd {
    style?: CSSProperties;
    recallAsapLead: () => void;
    msgFinal?: string;
    data: ILeadFirst
}

const RechercheEnd : React.FC<PropsRechercheEnd> = ({style, recallAsapLead, msgFinal, data}) => {

    useEffect (() => {
        if (data.anneetravaillees < 10 || new Date ().getFullYear () - parseInt (data.datenaissance, 10) < 40) {
            window.location.href = "/end"
        }
    }, [])

    return (
        <div style={style}>
            <Title style={{marginBottom: 30}}>Un expert est disponible pour répondre à vos questions, vous pouvez prendre rendez-vous directement via ce calendrier</Title>
            <iframe
                src="https://calendly.com/emg-sa/lpp"
                id="iframe-calendly"
                style={{width: "100%", height: 1000}}
                title="Rdv-Recherche"
            />
            <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                <Button
                    fnClick={() => window.location.href = "/end"}
                    type="outline"
                >Tout est clair</Button>
            </div>
        </div>
    )
}

export default RechercheEnd;