import React, { CSSProperties } from "react";
import "./Loader.css";
import Portrait from "../../assets/portrait.png";

interface PropsLoader {
    style?: CSSProperties;
}

const Loader : React.FC<PropsLoader> = ({style}) => {
    return (
        <div style={{
            display: "flex", 
            alignItems: "center", 
            marginTop: 40,
            paddingLeft: 35,
            paddingRight: 35,
            flexDirection: "column", 
            backgroundColor: "white", 
            maxWidth: 600,
            marginLeft: "auto", 
            marginRight: "auto", 
            borderColor: "rgba(0,0,0,.125)",
            borderStyle: "solid",
            borderWidth: 1, 
            borderRadius: 15}}
        >
            <div style={{
                margin: 12,
                marginTop: 20,
                marginBottom: 20,
                fontSize: "1.3rem",
                color: "#253439",
                display: "flex",
                alignItems: "center",
            }}>
                <img src={Portrait} style={{height: 75, borderRadius: 45}} alt="portrait"/>
                <span style={{marginLeft: 15}}>Nous préparons les éléments nécessaires. Veuillez patienter.</span>
            </div>
            <div className='loader loader1'>
                <div>
                    <div>
                    <div>
                        <div>
                        <div>
                            <div></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader;