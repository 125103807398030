import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import TunnelRecherche from './views/TunnelRecherche';
import TunnelPlacement from './views/TunnelPlacement';
import EndView from './views/End';
import DefaultTemplate from './template/default';
import Title from './components/Title';
import Subtitle from './components/Subtitle';
import { calculRentabilite, formatAmount } from './utils/functions';
import RangeSlider from './components/RangeSlider';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

function App() {

  const gtmParams = { id: 'GTM-M6TSGXR' }

  return (
    <GTMProvider state={gtmParams}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<TunnelRecherche />}/>
          <Route path='/r' element={<TunnelRecherche />}/>
          <Route path='/p' element={<TunnelPlacement />}/>
          <Route path='/end' element={<EndView />}/>
          <Route path='/test' element={<Test />}/>
          <Route
              path="*"
              element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    </GTMProvider>
  );
}

const Test = () => {
  const montantconnu = false;
  const montant = 100000;
  return (
    <DefaultTemplate>
      <Title>Toast</Title>
      {montantconnu ? <Test1 montant={montant} /> : <Test2 />}
    </DefaultTemplate>
  )
}
const Test1 = (props : any) => {
  const formatAmount = (a : number) => new Intl.NumberFormat('de-CH').format(a);
  const [duree, setDuree] = useState (8);
  const [rendement, setRendement] = useState (0.5);

  return (
    <>
      <p>Duree</p>
      <RangeSlider
        value={duree}
        max={20}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => setDuree (parseInt (e.target.value, 10))}
      />
      <p>Rendement</p>
      <RangeSlider
        value={rendement}
        max={10}
        step={0.1}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => setRendement (parseFloat (e.target.value))}
      />
      <div>
        <p>{formatAmount (props.montant)} CHF pendant {duree || 0} ans à {rendement.toString ().replace (".", ",")}% = <b>{formatAmount (calculRentabilite (props.montant, duree, rendement) - props.montant)} CHF</b></p>
        {/* <p>{formatAmount (props.montant)} CHF pendant {duree || 0} ans à 4,6% (Réaliste) = <b>{formatAmount (calculRentabilite (props.montant, duree, 4.6))} CHF</b></p>
        <p>{formatAmount (props.montant)} CHF pendant {duree || 0} ans à 6,8% (Optimiste) = <b>{formatAmount (calculRentabilite (props.montant, duree, 6.8))} CHF</b></p> */}
      </div>
    </>
  )
}
const Test2 = () => {
  const [duree, setDuree] = useState (8);
  const [rendement, setRendement] = useState (0.5);
  
  return (
    <>
      <Subtitle>Malheuresement, sans savoir le montant de vos avoirs, nous ne pouvons vous donner un chiffre, mais voici quelque exemples :</Subtitle>
      <p>Duree</p>
      <RangeSlider
        value={duree}
        max={20}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => setDuree (parseInt (e.target.value, 10))}
      />
      <p>Rendement</p>
      <RangeSlider
        value={rendement}
        max={10}
        step={0.1}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => setRendement (parseFloat (e.target.value))}
      />
      <div>
        <p>50’000 CHF pendant {duree} ans à {rendement.toString ().replace (".", ",")}% = <b>{formatAmount (calculRentabilite (50000, duree, rendement) - 50000, 'de-CH')} CHF</b></p>
        <p>100’000 CHF pendant {duree} ans à {rendement.toString ().replace (".", ",")}% = <b>{formatAmount (calculRentabilite (100000, duree, rendement) - 100000, 'de-CH')} CHF</b></p>
        <p>300’000 CHF pendant {duree} ans à {rendement.toString ().replace (".", ",")}% = <b>{formatAmount (calculRentabilite (300000, duree, rendement) - 300000, 'de-CH')} CHF</b></p>
      </div>
    </>
  )
}

export default App;
