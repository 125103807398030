import React, { CSSProperties } from "react";
import "./Subtitle.css";

interface PropsSubtitle {
    style?: CSSProperties;
}

const Subtitle : React.FC<PropsSubtitle> = ({children, style}) => {
    return (
        <p className="subtitle-p" style={style}>
            {children}
        </p>
    )
}

export default Subtitle;