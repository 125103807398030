import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Tile from "../../../components/Tile";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

const QUESTION = "Pour qui effectuez-vous la demande ?";
const INFO = "Cette information est requise pour connaître le nombre de documents nécessaire";

interface PropsRechercheStep1 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep1 = React.forwardRef<HTMLDivElement, PropsRechercheStep1> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        modifyData ({...data, origindemande: tileselected}, 1)
    }
    
    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Tile 
                fnClick={() => _onPress ({id: 1, title: "Demande pour soi-même"})} 
                active={data.origindemande.id === 1}
                style={{marginBottom: 20}}>
            J'effectue cette demande pour moi-même</Tile>
            <Tile 
                fnClick={() => _onPress ({id: 2, title: "Demande pour quelqu'un d'autre"})} 
                active={data.origindemande.id === 2}>
            J'effectue cette demande pour quelqu'un d'autre</Tile>
        </div>
    )
});

export default RechercheStep1;