import React, { useEffect } from "react";
import Loader from "../../../components/Loader";
import { ILeadPlacement } from "../../../interfaces/lead";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep12 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {

    useEffect (() => {
        setTimeout (() => {
            validateStep (data);
        }, 4000)
    })

    return (
        <Loader />
    )
}

export default PlacementStep12;