import React, { CSSProperties, useEffect } from "react";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import { ILeadPlacement } from "../../../interfaces/lead";

interface PropsPlacementEnd{
    style?: CSSProperties;
    recallAsapLead: () => void;
    msgFinal?: string;
    data: ILeadPlacement;
}

const PlacementEnd : React.FC<PropsPlacementEnd> = ({style, recallAsapLead, msgFinal, data}) => {
    useEffect (() => {
        if (data.montantlpp.id < 2 || (data.montantlpp.id === 5 && parseInt(data.montantlpp.title,10) < 25000)) {
            window.location.href = "/end";
        }
    }, [])

    return (
        <div style={style}>
            <Title style={{marginBottom: 45}}>
                Un expert est disponible pour répondre à vos questions, vous pouvez prendre rendez-vous directement via ce calendrier
            </Title>
            <iframe
                src="https://calendly.com/emg-sa/lpp"
                id="iframe-calendly"
                style={{width: "100%", height: 1000}}
                title="Rdv-Placement"
            />
            <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                <Button
                    fnClick={() => window.location.href = "/end"}
                    type="outline"
                >Tout est clair</Button>
            </div>
        </div>
    )
}

export default PlacementEnd;