import React, { CSSProperties } from "react";
import "./Button.css";

interface PropsButton {
    fnClick: (...args : any[]) => any;
    disabled?: boolean;
    style?: CSSProperties;
    type?: "block" | "outline" | "inline" | undefined
}

const Button : React.FC<PropsButton> = ({children, fnClick, disabled = false, style, type = "block"}) => {
    if (type === "inline") {
        return (
            <button onClick={fnClick} disabled={disabled} style={style} className="button-inline">
                {children}
            </button>
        )
    }

    if (type === "outline") {
        return (
            <button onClick={fnClick} disabled={disabled} style={style} className="button-outline">
                {children}
            </button>
        )
    }

    return (
        <button onClick={fnClick} className="button-button" disabled={disabled} style={style}>
            {children}
        </button>
    )
}

export default Button;

