import React, { useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Tile from "../../../components/Tile";
import Info from "../../../components/Info";
import Back from "../../../components/Back";
import { Col, Row } from "react-bootstrap";
import Cardinfo from "../../../components/Cardinfo";

const QUESTION = "Connaissez-vous le montant de vos avoirs de libre-passage ?";
const INFO = "Cette information nous est utile pour vous orienter sur une compagnie ou une autre en fonction du montant";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep2 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [tileSelected, setTileSelected] = useState (data.connaissancemontantlpp);

    const _onPress = () => {
        validateStep ({...data, connaissancemontantlpp: tileSelected})
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            <Cardinfo style={{marginBottom: 40}} message={INFO+"."} />
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            <Row className="gx-5">
                <Col xs={12} sm={6}>
                    <Tile
                        fnClick={() => setTileSelected ({id: 1, title: "Montant des avoirs connue"})} 
                        active={tileSelected.id === 1}
                        style={{marginBottom: 20}}
                    >
                        Oui
                    </Tile>
                </Col>
                <Col xs={12} sm={6}>
                    <Tile 
                        fnClick={() => setTileSelected ({id: 2, title: "Montant des avoirs inconnue"})} 
                        active={tileSelected.id === 2}>
                    Non</Tile>
                </Col>
            </Row>
            <Button style={{marginTop: 30}} fnClick={_onPress} disabled={tileSelected.id === 0}>Continuer</Button>
        </motion.div>
    )
}

export default PlacementStep2;