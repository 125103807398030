import React, { useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Info from "../../../components/Info";
import Back from "../../../components/Back";
import { Col, Row } from "react-bootstrap";
import InputText from "../../../components/InputText";
import { nameValidation } from "../../../utils/functions";
import Cardinfo from "../../../components/Cardinfo";

const QUESTION = "Indiquez votre nom";
const INFO = "Ces informations nous permettent de créer votre profil";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep9 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [nom, setNom] = useState (data.nom);
    const [prenom, setPrenom] = useState (data.prenom);

    const _onPress = () => {
        validateStep ({...data, nom, prenom})
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            <Cardinfo style={{marginBottom: 40}} message={INFO+"."} />
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            <Row style={{marginBottom: 20}} className="gx-5">
                <Col xs={12} sm={6} style={{marginBottom: 10}}>
                    <InputText
                        id="name"
                        name="lname"
                        placeholder="Nom"
                        aria-label="Nom"
                        value={nom}
                        onChange={(e : any) => {if (e.target.value.length === 0  || nameValidation (e.target.value)) setNom (e.target.value)}}
                        style={{width: '100%'}}
                        valid={nom.length > 1}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <InputText
                        id="fname"
                        name="fname"
                        placeholder="Prenom"
                        aria-label="Prenom"
                        value={prenom}
                        onChange={(e : any) => {if (e.target.value.length > 0  && nameValidation (e.target.value)) setPrenom (e.target.value)}}
                        style={{width: '100%'}}
                        valid={prenom.length > 1}
                    />
                </Col>
            </Row>
            <Button fnClick={_onPress} disabled={nom.length < 2 || prenom.length < 2}>Continuer</Button>
        </motion.div>
    )
}

export default PlacementStep9;