import React, {useState} from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonCustom from "../Button";

import SMS from "../../assets/sms.png";

import { E164Number } from "libphonenumber-js/types"
import InputCode from "../InputCode";

const WIDTH = window.innerWidth;

const ModalTelephone = ({show, handleClose, phone, sendSMS, verifiedPhone} : {show : boolean, handleClose: () => void, phone?: E164Number, sendSMS: () => void, verifiedPhone: (code: string) => void}) => {

    const [code, setCode] = useState ("");

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Vérification SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<div className="d-flex justify-content-center" style={{marginBottom: 15}}>
                        <img
                            src={SMS}
                            alt="sms"
                            width={111}
                        />
                    </div>}
                    {WIDTH > 576 && <p style={{textAlign: "center"}}>
                        Afin de vérifier votre identité, nous vous avons envoyé un code de vérification par SMS au numéro :
                        <br />
                        <span style={{color: "#1565c0"}}>{phone}</span>
                    </p>}
                    <p style={{textAlign: "center"}}>Entrez le code ici</p>
                    <InputCode setCode={setCode} />
                    <div className="d-flex justify-content-center" style={{marginTop: 30}}>
                        {WIDTH > 576 ?
                        <ButtonCustom
                            fnClick={() => verifiedPhone (code)}
                            disabled={code !== "6121"}
                        >Valider
                        </ButtonCustom>
                            :
                        <ButtonCustom
                            fnClick={() => verifiedPhone (code)}
                            disabled={code !== "6121"}
                            style={{padding: 8}}
                        >Valider
                        </ButtonCustom>
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {WIDTH > 576 && <Button variant="secondary" onClick={handleClose}>
                    Changer le numéro de téléphone
                </Button>}
                <Button variant="secondary" onClick={sendSMS}>
                    Renvoyer le code
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalTelephone;