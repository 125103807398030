import React, { CSSProperties } from "react";
import "./Chip.css";

interface PropsChip {
    fnClick: (...args : any[]) => void;
    style?: CSSProperties;
    className?: string;
}

const Chip : React.FC<PropsChip> = ({children, fnClick, style, className = ""}) => {
    return (
        <span onClick={fnClick} style={style} className={`chip ${className}`}>
            {children}
        </span>
    )
}

export default Chip;