import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Tile from "../../../components/Tile";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

const QUESTION = "Quel est votre lieu de résidence ?";
const INFO = "Cette information est utile pour accélérer les démarches";

interface PropsRechercheStep2 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep2 = React.forwardRef<HTMLDivElement, PropsRechercheStep2> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        modifyData ({...data, domiciliation: tileselected}, 2)
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            <Tile 
                fnClick={() => _onPress ({id: 1, title: "Domicilié en Suisse"})} 
                active={data.domiciliation.id === 1}
                style={{marginBottom: 20}}>
            Je suis domicilié(e) en <b>Suisse</b></Tile>
            <Tile 
                fnClick={() => _onPress ({id: 2, title: "Domicilié en France"})} 
                active={data.domiciliation.id === 2}
                style={{marginBottom: 20}}>
            Je suis domicilié(e) en <b>France</b></Tile>
            <Tile 
                fnClick={() => _onPress ({id: 3, title: "Domicilié dans un autre pays"})} 
                active={data.domiciliation.id === 3}>
            Je suis domicilié(e) dans un autre pays</Tile>
        </div>
    )
});

export default RechercheStep2;