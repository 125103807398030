export const nameValidation = (v: string) => v.match (/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u); 
export const calculRentabilite = (montant : number, duree : number, interet: number) => {
    for (let index = 1; index <= duree; index++) {
        montant = montant + interet/100 * montant
    }
    return Math.round (montant);
}
export const formatAmount = (a : number, local: string) => new Intl.NumberFormat(local).format(a);
export const parseAmount = (a: string, s: string) => a.replaceAll (s, "");

export const getAgeFromDate = (bd: string) => {
    if (!bd)
        return 0;
    const today = new Date ();
    const birthDate = new Date (bd);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const onlyNumbers = (v : string) => v.match (/^[0-9]*$/);
export const inputyearvalid = (s: string) => s.length === 4 && onlyNumbers (s) && (new Date ().getFullYear () - parseInt (s, 10) >= 18) && (new Date ().getFullYear () - parseInt (s, 10) <= 80)
