import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";
import InputMask from 'react-input-mask';
import InputText from "../../../components/InputText";
import Subtitle from "../../../components/Subtitle";
import Button from "../../../components/Button";

const QUESTION = "Indiquez votre numéro AVS ?";
const INFO = "Nous fournir ce numéro unique nous permet de traiter votre demande plus rapidement et sans aucune erreur possible";

interface PropsRechercheStep {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
    nextStep: (payload? : ILead, forcestep? : number) => void;
}

const RechercheStep9 = React.forwardRef<HTMLDivElement, PropsRechercheStep> (({children, modifyData, data, style, nextStep}, ref) => {

    const _onChange = (numavs : string) => {
        modifyData ({...data, numavs}, 9)
    }
    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}{"   "}<span style={{fontSize: "1.1rem"}}>(Facultatif)</span></Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Subtitle style={{marginBottom: 5}}>Vous trouverez ce numéro sur votre carte d'assurance ou contrat de prévoyance.</Subtitle>
            <span style={{fontSize: "1.1rem", color: "#778899a6"}}>Format: 756.XXXX.XXXX.XX</span>
            <div style={{display: "flex", marginTop: 15}}>
                <InputMask mask="756.9999.9999.99" value={data.numavs} onChange={(e) => _onChange (e.target.value)} alwaysShowMask>
                    <InputText valid={data?.numavs?.replace (/[^0-9]/g, '').length === 13} pattern="\d*" />
                </InputMask>
                <Button
                    fnClick={() => nextStep (data, 10)}
                    type="inline"
                    style={{marginLeft: 20}}
                >Remplir ultérieurement</Button>
            </div>
        </div>
    )
});

export default RechercheStep9;