import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

import {AiOutlineWoman, AiOutlineMan} from "react-icons/ai"
import CardTile from "../../../components/CardTile";

const QUESTION = "Quelle est votre civilité ?";
const INFO = "Cette information nous est utile pour vous indiquer les dates de retrait possibles";

interface PropsRechercheStep5 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep5 = React.forwardRef<HTMLDivElement, PropsRechercheStep5> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        modifyData ({...data, sexe : tileselected}, 5)
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <div style={{display: "flex"}}>
                <CardTile 
                    Icon={AiOutlineWoman}
                    fnClick={() => _onPress ({id: 1, title: "Femme"})} 
                    active={data.sexe.id === 1}
                    style={{marginBottom: 20}}>
                Je suis une femme</CardTile>
                <div style={{width: 30}}/>
                <CardTile
                    Icon={AiOutlineMan}
                    fnClick={() => _onPress ({id: 2, title: "Homme"})} 
                    active={data.sexe.id === 2}
                    style={{marginBottom: 20}}>
                Je suis un homme</CardTile>
            </div>
        </div>
    )
});

export default RechercheStep5;