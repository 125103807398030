import React, { CSSProperties } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import {IoInformationCircleSharp} from "react-icons/io5";

interface InfoProps {
    title: string;
    text?: string;
    style?: CSSProperties;
    tooltip?: boolean;
}

const Info: React.FC<InfoProps> = ({title, text, style, tooltip = true}) => {

    const renderTooltip = (props : any) => (
        <Tooltip id="button-tooltip" {...props}>
            <b>{title}</b>
            {text}
        </Tooltip>
    )

    if (tooltip) {
        return (
            <OverlayTrigger
                placement="bottom"
                delay={{show: 250, hide: 400}}
                overlay={renderTooltip}
            >
                <span style={{fontSize: "1.35rem", color: "#778899", marginLeft: 20, display: "inline-flex", marginTop: 6}}>
                    <IoInformationCircleSharp/>
                </span>
            </OverlayTrigger>
        )
    }

    return (
        <div style={{marginTop: '20px', fontSize: '1.1rem', color: '#778899a6', paddingLeft:5, paddingRight: 5, ...style}}>
            <p><FaQuestionCircle />{'  '}
                <b>{title}</b> 
                {text}
            </p>
        </div>
    )
}

export default Info;