import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Tile from "../../../components/Tile";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

const QUESTION = "Quel est votre régime d'assurance maladie ?";
const INFO = "Cette information nous est utile pour vous informer sur l'aspect fiscal du capital de prévoyance";

interface PropsRechercheStep2 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep3 = React.forwardRef<HTMLDivElement, PropsRechercheStep2> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        modifyData ({...data, regimeassurance: tileselected}, 3)
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Tile 
                fnClick={() => _onPress ({id: 1, title: "Affilié à LAMal"})} 
                active={data.regimeassurance.id === 1}
                style={{marginBottom: 20}}>
            Je suis affilié(e) à la <b>LAMal</b></Tile>
            <Tile 
                fnClick={() => _onPress ({id: 2, title: "Affilié à la CMU (CNTFS)"})} 
                active={data.regimeassurance.id === 2}
                style={{marginBottom: 20}}>
            Je suis affilié(e) à la <b>CMU (CNTFS)</b></Tile>
            <Tile 
                fnClick={() => _onPress ({id: 3, title: "Affilié à un autre régime d'assurance maladie"})} 
                active={data.regimeassurance.id === 3}>
            Je suis affilié(e) à un autre régime d'assurance maladie</Tile>
        </div>
    )
});

export default RechercheStep3;