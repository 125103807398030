import React, { CSSProperties } from "react";
import "./Tile.css";

interface PropsTile {
    fnClick : (...args : any[]) => any;
    active?: boolean;
    style?: CSSProperties;
    hidecheckbox?: boolean; 
}

const Tile : React.FC<PropsTile> = ({children, fnClick, active = false, style, hidecheckbox = false}) => {
    return (
        <div className={`tile-ctn ${active ? "active" : ""}`} onClick={fnClick} style={style}>
            <div className="tile-group">
                {!hidecheckbox && <span className="tile-radio"></span>}
                <span className="tile-text">{children}</span>
            </div>
        </div>
    )
}

export default Tile;