import { E164Number } from "libphonenumber-js/types";
import React, {useEffect} from "react";
import { Container, ProgressBar } from "react-bootstrap";
import Cardinfo from "../components/Cardinfo";
import Header from "../components/Header";
import ModalTelephone from "../components/ModalTelephone";

import "./default.css";

const WIDTH = window.innerWidth;

interface PropsDefaultTemplate {
    showModal? : boolean;
    handleCloseModal? : () => void;
    phoneModal?: E164Number;
    sendSMSModal?: () => void;
    verifiedPhoneModal?: (code: string) => void;
    step?: number;
    maxstep?: number;
    showcardinfo?: boolean;
    cardinfomsg?: string;
    cardinfophoto?: string;
    showprogressbar?: boolean;
    cardinfoposition?: "bottom" | "top" | undefined
    dangeroushtmlcardinfo?: boolean;
}

const DefaultTemplate: React.FC<PropsDefaultTemplate> = ({children, showModal, handleCloseModal, phoneModal, sendSMSModal, verifiedPhoneModal, step, maxstep, showcardinfo = true, cardinfomsg, cardinfophoto, showprogressbar = true, cardinfoposition = "top", dangeroushtmlcardinfo}) => {
    useEffect (() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Header />
            <Container className="main-container" style={{paddingBottom: 50}}>
                {showprogressbar && step && maxstep && <ProgressBar now={(Math.pow (step, 1/1.3)/ Math.pow (maxstep, 1/1.3)) * 100} variant="pbstep" 
                    style={{marginBottom: WIDTH > 576 ? 60: 20, height: 7, position: "sticky", width: '100%', top: 0, maxWidth: '776px'}} />}
                {showcardinfo && cardinfophoto && cardinfoposition === "top" && 
                    <Cardinfo style={{marginBottom: 60}} photo={cardinfophoto} message={cardinfomsg} dangeroushtml={dangeroushtmlcardinfo} />
                }
                {children}
                {showcardinfo && cardinfophoto && cardinfoposition === "bottom" && 
                    <Cardinfo style={{marginTop: 60}} message={cardinfomsg} photo={cardinfophoto} dangeroushtml={dangeroushtmlcardinfo}/>
                }
            </Container>
            {showModal && handleCloseModal && sendSMSModal && verifiedPhoneModal &&
                <ModalTelephone 
                    show={showModal}
                    handleClose={handleCloseModal}
                    phone={phoneModal}
                    sendSMS={sendSMSModal}
                    verifiedPhone={verifiedPhoneModal}
                />
            }
        </>
    )
}

export default DefaultTemplate;