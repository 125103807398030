import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Back from "../../../components/Back";
import Info from "../../../components/Info";
import Cardinfo from "../../../components/Cardinfo";
import Photo from "../../../assets/portrait.png";
import RangeSlider from "../../../components/RangeSlider";
import Chip from "../../../components/Chip";

const QUESTION = "Quel est votre profil de prévoyance ?";
const INFO = "Cette information nous permet de préparer au mieux notre accompagnement";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep6 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [range, setRange] = useState (data.profilprevoyance.id);

    const reloads = useRef (0);
    useEffect (() => {if (range !== data.profilprevoyance.id) reloads.current++}, [range])

    const _onPress = () => {
        const profilprevoyance = {id: range, title: getTitlePrevoyance (range)};
        validateStep ({...data, profilprevoyance})
    }

    useEffect (() => {
        if (data.profilprevoyance.id === 0)
            setRange (getRangeFromBirthday (data.datenaissance));
    }, [])

    const getRangeFromBirthday = (bd : string) => {
        const anneerestantes = 63 - getAgeFromDate (bd);
        if (anneerestantes < 10) return 1;
        if (anneerestantes >= 10 && anneerestantes < 15) return 2;
        if (anneerestantes >= 15 && anneerestantes < 25) return 3;
        if (anneerestantes >= 25 && anneerestantes < 35) return 4;
        if (anneerestantes >= 35) return 5;
        return 1;
    }

    const getTitlePrevoyance = (r : number) => {
        switch (r) {
            case 1:
                return "Soucieux de la sécurité";
            case 2:
                return "Prudent";
            case 3:
                return "Equilibré";
            case 4:
                return "Ambitieux";
            case 5:
                return "Orienté opportunité"
            default:
                return "";
        }
    }

    const getAgeFromDate = (bd : string) => {
        if (!bd)
            return 0;
        const today = new Date ();
        const birthDate = new Date (bd);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            {/* <Cardinfo style={{marginBottom: 40}} message={INFO+"."} /> */}
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Chip fnClick={() => setRange (1)} style={{marginRight: 8, marginBottom: 5}} className={range === 1 ? "active" : ""}>Soucieux de la sécurité</Chip>
                <Chip fnClick={() => setRange (2)} style={{marginRight: 8, marginBottom: 5}} className={range === 2 ? "active" : ""}>Prudent</Chip>
                <Chip fnClick={() => setRange (3)} style={{marginRight: 8, marginBottom: 5}} className={range === 3 ? "active" : ""}>Equilibré</Chip>
                <Chip fnClick={() => setRange (4)} style={{marginRight: 8, marginBottom: 5}} className={range === 4 ? "active" : ""}>Ambitieux</Chip>
                <Chip fnClick={() => setRange (5)} style={{marginRight: 8, marginBottom: 5}} className={range === 5 ? "active" : ""}>Orienté opportunité</Chip>
            </div>
            <div style={{display: "flex", alignItems: "center"}} className="range-slider-lpp">
                <span className="helpers" style={{marginRight: 16}}>Sécurité</span>
                <RangeSlider
                    min={1}
                    max={5}
                    step={1}
                    value={range}
                    onChange={(e) => setRange (parseInt (e.target.value, 10))}
                    style={{flex: 1}}
                    className="range-slider-lpp-range"
                    stripped
                    stepstripped
                />
                <span className="helpers" style={{marginLeft: 16}}>Rendement</span>
            </div>
            <Cardinfo photo={Photo} style={{marginTop: 25}}>
                Votre profil prévoyance : <span style={{fontWeight: "bold", color: "limegreen"}}>{getTitlePrevoyance (range)}</span><br />
                {!reloads.current && data.profilprevoyance.id === 0 && <span style={{fontSize: "1.1rem"}}>Selectionné à partir de votre âge</span>}
            </Cardinfo>
            <div style={{display: "flex", alignItems: "center", marginTop: 30}}>
                <Button fnClick={_onPress}>Continuer</Button>
                <Button type="inline" fnClick={_onPress} style={{marginLeft: 15}}>Passer cette étape</Button>
            </div>
        </motion.div>
    )
}

export default PlacementStep6;