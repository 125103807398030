import React, { CSSProperties } from "react";
import "./Title.css";

interface PropsTitle {
    style?: CSSProperties
}

const Title : React.FC<PropsTitle> = ({children, style}) => {
    return (
        <h1 className="title-h1" style={style}>
            {children}
        </h1>
    )
}

export default Title;