import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Info from "../../../components/Info";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

import Subtitle from "../../../components/Subtitle";
import DatePicker from "../../../components/DatePicker";
import { inputyearvalid, onlyNumbers } from "../../../utils/functions";
import InputText from "../../../components/InputText";

const QUESTION = "Quelle est votre année de naissance ?";
const INFO = "Cette information nous est utile pour vous indiquer les dates de retrait possibles";

const WIDTH = window.innerWidth;

interface PropsRechercheStep6 {
    modifyData: (data: ILead, step: number) => void;
    isDateValid: (...args : any[]) => any;
    getAgeFromDate: (...args : any[]) => number;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep6 = React.forwardRef<HTMLDivElement, PropsRechercheStep6> (({children, modifyData, data, style, isDateValid, getAgeFromDate}, ref) => {

    const reloads = useRef (0);
    const [datepfocus, setDatepfocus] = useState (false);

    useEffect (() => {
        reloads.current++;
    }, [data]);

    const _onChange = (datenaissance : string) => {
        modifyData ({...data, datenaissance}, 6);
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            {isDateValid () && getAgeFromDate () > 0 &&
                <Subtitle>J'ai <b>{getAgeFromDate ()} {getAgeFromDate () > 1 ? "ans" : "an"}</b>.</Subtitle>
            }
            {/* <DatePicker
                autoFocus={!(isDateValid () && getAgeFromDate () >= 18 && getAgeFromDate () <= 80) && (WIDTH > 756)}
                setDatepfocus={setDatepfocus}
                birthdate={data.datenaissance}
                fnChange={_onChange}
                className={isDateValid () && getAgeFromDate () >= 18 && getAgeFromDate () <= 80 ? "valid" : ""}
                error={reloads.current > 1 && (!isDateValid () || getAgeFromDate () < 18 || getAgeFromDate () > 80 || isNaN (getAgeFromDate ())) && (data.datenaissance.length === 10 || !datepfocus)  ? "Vous ne remplissez pas les prérequis" : undefined}
            /> */}
            <InputText
                id="birthyear"
                name="birthyear"
                placeholder="YYYY"
                aria-label="Année de naissance"
                value={data.datenaissance}
                onChange={(e : any) => {
                    if (onlyNumbers (e.target.value) && e.target.value.length <= 4)
                        _onChange (e.target.value);
                }}
                valid={inputyearvalid (data.datenaissance)}
                className={inputyearvalid (data.datenaissance) ? "valid" : ""}
                error={reloads.current > 1 && !inputyearvalid (data.datenaissance) && !datepfocus ? "Vous ne remplissez pas les prérequis" : undefined}
                onFocus={() => setDatepfocus (true)}
                onBlur={() => setDatepfocus (false)}
            />
        </div>
    )
});

export default RechercheStep6;