import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";
import RangeSlider from "../../../components/RangeSlider";
import Subtitle from "../../../components/Subtitle";

import {ImPlus, ImMinus} from "react-icons/im"

const QUESTION = "Combien d'années avez-vous travaillé(e)s en Suisse ?";
const INFO = "Cette information nous est utile afin de déterminer le montant de votre LPP"

interface PropsRechercheStep4 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep4 = React.forwardRef<HTMLDivElement, PropsRechercheStep4> (({children, modifyData, data, style}, ref) => {
    
    const _onPress = (anneetravaillees : any) => {
        modifyData ({...data, anneetravaillees}, 4)
    }
    
    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Subtitle>J'ai travaillé <b>{`${data.anneetravaillees} ${data.anneetravaillees > 1 ? "années" : "année"}`}</b> en Suisse.</Subtitle>
            <div style={{display: "flex", alignItems: "center"}} className="range-slider-lpp">
                <span className="helpers" style={{marginRight: 16, fontSize: "1.1rem", cursor: "pointer"}}
                    onClick={() => _onPress (data.anneetravaillees - 1)}
                ><ImMinus /></span>
                <RangeSlider
                    min={1}
                    max={40}
                    step={1}
                    value={data.anneetravaillees}
                    onChange={(e) => _onPress (parseInt (e.target.value, 10))}
                    style={{flex: 1}}
                    className="range-slider-lpp-range"
                    stripped
                />
                <span className="helpers" style={{marginLeft: 16, fontSize: "1.1rem", cursor: "pointer"}}
                    onClick={() => _onPress (data.anneetravaillees + 1)}
                ><ImPlus/></span>
            </div>
        </div>
    )
});

export default RechercheStep4;