import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import { E164Number } from "libphonenumber-js/types";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import InputText from "../../../components/InputText";

import "./Step12.css";
import Cardinfo from "../../../components/Cardinfo";
import Portrait from "../../../assets/portrait.png";
import Security from "../../../assets/security.gif";
import Button from "../../../components/Button";

const QUESTION = "Vérifiez votre identité";
const INFO = "Un code unique vous sera envoyé";

interface PropsRechercheStep {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
    nextStep: (...args: any[]) => any;
}

const RechercheStep12 = React.forwardRef<HTMLDivElement, PropsRechercheStep> (({children, modifyData, data, style, nextStep}, ref) => {

    const cardinfomsg = `Pour des raisons de confidentialité, une vérification doit être effectuée pour confirmer que nous avons bien affaire à <b>${data.prenom} ${data.nom}</b>`;
    
    const _onChange = (telephone : E164Number) => {
        modifyData ({...data, telephone}, 12)
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 0, marginTop: 50}}>
                <img src={Security} alt="security" style={{height: 50}}/>
                <Title style={{margin: 0}}>{QUESTION}</Title>
            </div>
            <Info title={INFO} tooltip={false} style={{fontSize: "0.9rem", color: "#778899a6", textAlign: "center", marginBottom: 30}}/>
            <div style={{display: "flex", justifyContent: "center", marginBottom: 35}}>
                <PhoneInputWithCountrySelect
                    placeholder="Numéro de téléphone"
                    value={data.telephone}
                    onChange={_onChange}
                    defaultCountry="CH"
                    inputComponent={PhoneInput}
                    valid={data.telephone && isValidPhoneNumber (data.telephone as string)}

                />
            </div>
            <div className="continue-block" style={{justifyContent: "center"}}>
                <Button
                    fnClick={nextStep}
                    disabled={!(data.telephone && isValidPhoneNumber (data.telephone as string))}
                >Obtenir mon code</Button>
            </div>
            <Cardinfo style={{marginTop: 60}} message={cardinfomsg} photo={Portrait} dangeroushtml={true}/>
        </div>
    )
});

const PhoneInput = React.forwardRef<any, any>((props, ref) => {
    return (
        <InputText
            ref={ref}
            id="phone"
            placeholder="Phone"
            aria-label="phone"
            aria-describedby="phone-addon"
            type="tel"
            autoComplete="tel"
            style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: 280, boxShadow: "unset"}}
            {...props}
        />
    )
})

export default RechercheStep12;