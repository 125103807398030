import React, { CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import Info from "../../../components/Info";
import Tile from "../../../components/Tile";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

const QUESTION = "Exercez-vous une activité lucrative ?";
const INFO = "Cette information nous permet d'accélérer les recherches";

interface PropsRechercheStep7 {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep7 = React.forwardRef<HTMLDivElement, PropsRechercheStep7> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        modifyData ({...data, occupation : tileselected}, 7)
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Row className="gx-5">
                <Col xs={12} sm={6}>
                    <Tile
                        fnClick={() => _onPress ({id: 1, title: "Exerce une activité lucrative"})} 
                        active={data.occupation.id === 1}
                        style={{marginBottom: 20}}
                    >
                        Oui
                    </Tile>
                </Col>
                <Col xs={12} sm={6}>
                    <Tile 
                        fnClick={() => _onPress ({id: 2, title: "N'exerce pas d'activité lucrative"})} 
                        active={data.occupation.id === 2}
                        style={{marginBottom: 20}}>
                    Non</Tile>
                </Col>
            </Row>
        </div>
    )
});

export default RechercheStep7;