import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";
import { Col, Row } from "react-bootstrap";
import InputText from "../../../components/InputText";
import { nameValidation } from "../../../utils/functions";

const QUESTION = "Indiquez votre nom";
const INFO = "Ces informations nous permettent de créer votre profil";

interface PropsRechercheStep {
    modifyData: (data: ILead, step: number) => void;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep10 = React.forwardRef<HTMLDivElement, PropsRechercheStep> (({children, modifyData, data, style}, ref) => {

    const _onChange = (key : string, value: string) => {
        if (value.length > 0 && !nameValidation (value)) {
            return;
        }
        modifyData ({...data, [key] : value}, 10)
    }
    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            <Row style={{marginBottom: 40}} className="gx-5">
                <Col xs={12} sm={6} style={{marginBottom: 10}}>
                    <InputText
                        id="name"
                        name="lname"
                        placeholder="Nom"
                        aria-label="Nom"
                        value={data.nom}
                        onChange={(e : any) => _onChange ("nom", e.target.value)}
                        style={{width: '100%'}}
                        valid={data.nom.length > 1}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <InputText
                        id="fname"
                        name="fname"
                        placeholder="Prenom"
                        aria-label="Prenom"
                        value={data.prenom}
                        onChange={(e : any) => _onChange ("prenom", e.target.value)}
                        style={{width: '100%'}}
                        valid={data.prenom.length > 1}
                    />
                </Col>
            </Row>
        </div>
    )
});

export default RechercheStep10;