import React, { useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Info from "../../../components/Info";
import Subtitle from "../../../components/Subtitle";
import Tile from "../../../components/Tile";
import Back from "../../../components/Back";
import Cardinfo from "../../../components/Cardinfo";

const QUESTION = "Pour quelles raisons souhaitez-vous placer vos avoirs ?";
const LABELTILE1 = "Pour augmenter ma retraite";
const LABELTILE2 = "Pour acheter un bien en résidence principale";
const LABELTILE3 = "Pour ne pas perdre d'argent";
const LABELTILE4 = "Autres raisons";
const INFO = "Cette information nous permet d'améliorer votre accompagnement";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep5 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [raisonsselected, setraisonsselected] = useState (data.raisons);

    const _onPress = () => {
        validateStep ({...data, raisons: raisonsselected})
    }

    const changeRaison = (raison : any) => {
        const ids = raisonsselected.reduce<number []> ((prev, curr) => [...prev, curr.id], []);
        let newraisons = [];
        if (ids.includes (raison.id)) {
            newraisons = raisonsselected.filter (r => r.id !== raison.id)
        }
        else {
            newraisons = [...raisonsselected, raison];
        }
        setraisonsselected (newraisons);
    }

    const isRaisonSelected = (id: number) => {
        const ids = raisonsselected.reduce<number []> ((prev, curr) => [...prev, curr.id], []);
        return (ids.includes (id));
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}{"   "}<span style={{fontSize: "1.1rem"}}>(Facultatif)</span></Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Subtitle>Vous pouvez selectionner plusieurs raisons.</Subtitle>
            <Tile 
                fnClick={() => changeRaison ({id: 1, title: "Augmenter sa retraite"})} 
                active={isRaisonSelected (1)}
                style={{marginBottom: 20}}>
            {LABELTILE1}</Tile>
            <Tile 
                fnClick={() => changeRaison ({id: 2, title: "Achater un bien en résidence principale"})} 
                active={isRaisonSelected (2)}
                style={{marginBottom: 20}}>
            {LABELTILE2}</Tile>
            <Tile 
                fnClick={() => changeRaison ({id: 3, title: "Ne pas perdre d'argent"})} 
                active={isRaisonSelected (3)}
                style={{marginBottom: 20}}>
            {LABELTILE3}</Tile>
            <Tile 
                fnClick={() => changeRaison ({id: 4, title: "Autres"})} 
                active={isRaisonSelected (4)}
                style={{marginBottom: 20}}>
            {LABELTILE4}</Tile>
            <Button style={{marginTop: 30}} fnClick={_onPress}>Continuer</Button>
            <Cardinfo style={{marginTop: 40}} message={INFO+"."} />
        </motion.div>
    )
}

export default PlacementStep5;