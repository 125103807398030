import React, { CSSProperties } from "react";
import Info from "../../../components/Info";
import Subtitle from "../../../components/Subtitle";
import Tile from "../../../components/Tile";
import Title from "../../../components/Title";
import { ILeadFirst as ILead } from "../../../interfaces/lead";

const QUESTION = "Pour quelles raisons recherchez-vous vos avoirs ?";
const INFO = "Cette information nous permet d'améliorer votre accompagnement";

interface PropsRechercheStep {
    modifyData: (...args : any[]) => any;
    data: ILead;
    style?: CSSProperties;
}

const RechercheStep11 = React.forwardRef<HTMLDivElement, PropsRechercheStep> (({children, modifyData, data, style}, ref) => {

    const _onPress = (tileselected : any) => {
        const ids = data.raisons.reduce<number []> ((prev, curr) => [...prev, curr.id], []);
        let newraisons = [];
        if (ids.includes (tileselected.id)) {
            newraisons = data.raisons.filter (r => r.id !== tileselected.id)
        }
        else {
            newraisons = [...data.raisons, tileselected];
        }
        modifyData ({...data, raisons : newraisons}, 11)
    }

    const isRaisonSelected = (id: number) => {
        const ids = data.raisons.reduce<number []> ((prev, curr) => [...prev, curr.id], []);
        return (ids.includes (id));
    }

    return (
        <div style={style} ref={ref} className="container-step">
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}{"   "}<span style={{fontSize: "1.1rem"}}>(Facultatif)</span></Title>
                <Info title={INFO} tooltip={true}/>
            </div>
            <Subtitle>Vous pouvez selectionner plusieurs raisons.</Subtitle>
            <Tile 
                fnClick={() => _onPress ({id: 1, title: "Souhaite récupérer ses avoirs"})} 
                active={isRaisonSelected (1)}
                style={{marginBottom: 20}}>
            Je souhaite récupérer mes avoirs</Tile>
            <Tile 
                fnClick={() => _onPress ({id: 2, title: "Souhaite placer ses avoirs"})} 
                active={isRaisonSelected (2)}
                style={{marginBottom: 20}}>
            Je souhaite placer mes avoirs</Tile>
            <Tile 
                fnClick={() => _onPress ({id: 3, title: "Change d'employeur"})} 
                active={isRaisonSelected (3)}
                style={{marginBottom: 20}}>
            Je change d'employeur</Tile>
            <Tile 
                fnClick={() => _onPress ({id: 4, title: "Autres"})} 
                active={isRaisonSelected (4)}
                style={{marginBottom: 20}}>
            Autre(s) raison(s)</Tile>
        </div>
    )
});

export default RechercheStep11;