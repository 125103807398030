import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {BsTelephoneFill} from 'react-icons/bs'
import logofb from "../../assets/LOGO_LPP_FBLANC.svg";
import "./Header.css";

const TELEPHONE = "22 566 16 49";

const Header = () => {
    return (
        <header>
            <Container fluid="md">
                <Row>
                    <Col xs="12" md="4" className="d-flex d-md-block justify-content-center">
                        <img src={logofb} className="logo" alt="Logo ma-lpp"/>
                    </Col>
                    <Col md="8" className="d-none d-md-block" >
                        <div className="phone-block float-end">
                            <div className="phone-top d-flex align-items-center">
                                <span className="green-phone"><BsTelephoneFill /></span>
                                <a href={`tel:+41${TELEPHONE.replaceAll (" ", "")}`} className="green-phone fw-bold phone-number">0{TELEPHONE}</a>
                            </div>
                            <div className="phone-bottom">
                                <span className="green-phone fw-bold">Gratuit</span>
                                <span className="phone-hours">: Lundi - Vendredi 9h-19h</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header;