import React, { useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Info from "../../../components/Info";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import InputText from "../../../components/InputText";
import Security from "../../../assets/security.gif";
import Cardinfo from "../../../components/Cardinfo";

const QUESTION = "Vérifiez votre identité";
const INFO = "Indiquez votre numéro de téléphone afin de vous authentifier";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep13 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [telephone, setTelephone] = useState (data.telephone);

    const _onPress = () => {
        validateStep ({...data, telephone})
    }

    const cardinfomsg = `Pour des raisons de confidentialité, une vérification doit être effectuée pour confirmer que nous avons bien affaire à <b>${data.prenom} ${data.nom}</b>`;
    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 0, marginTop: 50}}>
                <img src={Security} alt="security" style={{height: 50}}/>
                <Title style={{margin: 0}}>{QUESTION}</Title>
            </div>
            <Info title={INFO} tooltip={false} style={{fontSize: "0.9rem", color: "#778899a6", textAlign: "center", marginBottom: 30}}/>
            <div style={{display: "flex", justifyContent: "center", marginBottom: 35}}>
                <PhoneInputWithCountrySelect
                    placeholder="Numéro de téléphone"
                    value={telephone}
                    onChange={setTelephone}
                    defaultCountry="CH"
                    inputComponent={PhoneInput}
                    valid={telephone && isValidPhoneNumber (telephone as string)}

                />
            </div>
            <div className="continue-block" style={{justifyContent: "center"}}>
            <Button fnClick={_onPress} disabled={!telephone || !isValidPhoneNumber (telephone as string)} style={{marginTop: 30}}>Obtenir mon code</Button>
            </div>
            <Cardinfo style={{marginTop: 60}} message={cardinfomsg} dangeroushtml={true}/>
        </motion.div>
    )
}

const PhoneInput = React.forwardRef<any, any>((props, ref) => {
    return (
        <InputText
            ref={ref}
            id="phone"
            placeholder="Phone"
            aria-label="phone"
            aria-describedby="phone-addon"
            type="tel"
            autoComplete="tel"
            style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: 280, boxShadow: "unset"}}
            {...props}
        />
    )
})

export default PlacementStep13;