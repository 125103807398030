import React, { useState, useEffect, useRef } from "react";
import { ILeadFirst as ILead } from "../../interfaces/lead";
import DefaultTemplate from "../../template/default";
import { functionsendpoint } from "../../utils/endpoint";
import RechercheStep1 from "../../steps/TunnelRecherche/Step1";
import RechercheStep2 from "../../steps/TunnelRecherche/Step2";
import Button from "../../components/Button";
import RechercheStep3 from "../../steps/TunnelRecherche/Step3";
import RechercheStep4 from "../../steps/TunnelRecherche/Step4";
import RechercheStep5 from "../../steps/TunnelRecherche/Step5";
import RechercheStep6 from "../../steps/TunnelRecherche/Step6";
import RechercheStep7 from "../../steps/TunnelRecherche/Step7";
import RechercheStep8 from "../../steps/TunnelRecherche/Step8";
import RechercheStep9 from "../../steps/TunnelRecherche/Step9";
import RechercheStep10 from "../../steps/TunnelRecherche/Step10";
import RechercheStep11 from "../../steps/TunnelRecherche/Step11";
import RechercheStep12 from "../../steps/TunnelRecherche/Step12";
import { isValidPhoneNumber } from "react-phone-number-input";
import Photo from "../../assets/portrait.png";
import RechercheEnd from "../../steps/TunnelRecherche/End";
import Loader from "../../components/Loader";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { inputyearvalid } from "../../utils/functions";
elementScrollIntoViewPolyfill();

const MAX_STEP = 12;
const WITDH = window.innerWidth;

const TunnelRecherche = () => {
    const sendDataToGTM = useGTMDispatch ()
    const [phonestep, setPhonestep] = useState (false);
    const [id, setId] = useState("");
    const [step, setStep] = useState(1);
    const [data, setData] = useState<ILead>({
        origindemande: { id: 1, title: "Demande pour soi-même" },
        domiciliation: { id: 0, title: "" },
        regimeassurance: { id: 0, title: "" },
        anneetravaillees: 15,
        sexe: { id: 0, title: "" },
        datenaissance: "",
        occupation: { id: 0, title: "" },
        rechercheemploi: { id: 0, title: "" },
        numavs: "",
        nom: "",
        prenom: "",
        raisons: [],
        telephone: undefined
    })
    const [leadcompleted, setLeadcompleted] = useState(false);
    const [msgFinal, setMsgFinal] = useState("");
    const [loader, setLoader] = useState(false);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false);

    ///NEW FUNCTIONS
    const modifyData = (payload: ILead, s: number) => {
        setData(payload);
        window.sessionStorage.setItem("data-recherche", JSON.stringify(payload));
        if ([1, 2, 3, 5, 7, 8].includes(s)) {
            if (s === step) {
                nextStep(payload)
            }
            else {
                scrollToStep(s + 1);
            }
        }
    }

    const nextStep = (payload?: ILead, forcestep?: number) => {
        let nextStep = step + 1;
        if (forcestep && nextStep > forcestep) {
            scrollToStep(forcestep);
            return
        }
        if (step === 2 && nextStep === 3 && (data.domiciliation.id === 1 || (payload && payload.domiciliation.id === 1))) {
            trackStep(nextStep);
            nextStep++;
        }
        if (step === 7 && nextStep === 8 && (data.occupation.id === 1 || (payload && payload.occupation.id === 1))) {
            trackStep(nextStep);
            nextStep++;
        }
        if (step === 10) {
            preSaveLead();
        }
        if (step === 12) {
            setPhonestep (true);
            captureLead();
            if (!phonestep){
                sendDataToGTM({ event: 'leadOk' })
                trackStep(12);
            }
            return;
        }
        trackStep(step);
        setStep(nextStep);
        window.sessionStorage.setItem("step-recherche", JSON.stringify(nextStep));
    }

    const trackStep = async (s: number) => {
        const _url = `${functionsendpoint}/trackingsteplpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tunnel: "Recherche", step: s.toString(), labelstep: getLabelStep(s) })

            });
        } catch (error) {
            console.log(error);
        }
    }

    const getLabelStep = (s: number) => {
        switch (s) {
            case 1:
                return "Origine demande"
            case 2:
                return "Domiciliation"
            case 3:
                return "Regime assurance (Facultatif) (Skippable)"
            case 4:
                return "Années travaillées"
            case 5:
                return "Civilité"
            case 6:
                return "Date naissance"
            case 7:
                return "Exercice activité lucrative"
            case 8:
                return "Recherche un emploi en Suisse (Facultatif) (Skippable)"
            case 9:
                return "Numéro AVS"
            case 10:
                return "Nom Prénom"
            case 11:
                return "Raisons recherche avoirs (Facultatif)"
            case 12:
                return "Téléphone"
            default:
                return "";
        }
    }

    const captureLead = () => {
        sendLead();
        sendSMS();
        handleShow();
    }

    const sendLead = async () => {
        if (!data.telephone)
            return;
        const _url = `${functionsendpoint}/addleadlpp`;
        try {
            const response = await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data, type: "recherche avoirs", ...(id && { _id: id }) })

            });
            const result = await response.json();
            setId(result._doc._id);
        } catch (error) {
            console.log(error);
        }
    }

    const preSaveLead = async () => {
        if (!data.nom || !data.prenom)
            return;
        const _url = `${functionsendpoint}/addleadlpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data, type: "recherche avoirs", ...(id && { _id: id }) })

            });
        } catch (error) {
            console.log(error);
        }
    }

    const sendSMS = async () => {
        if (!data.telephone)
            return;
        const _url = `${functionsendpoint}/sendSMS?phone=${data.telephone.replace("+", "%2B")}`;
        try {
            await fetch(_url);
        } catch (error) {
            console.log(error)
        }
    }

    const recallAsapLead = async () => {
        // const _url = "`${functionsendpoint}/recalllead`";
        // try {
        //     const response = await fetch (_url, {
        //         method: 'POST',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({_id: id})
        //     });
        //     const data = await response.json ();
        //     setMsgFinal (data.message);
        // } catch (error) {
        //     console.log (error);
        // }
        const _url = "https://comparea.ch/mailing/sms/recall.php?site=LPP&client=" + data.telephone?.replace("+", "");
        await fetch(_url);
        setMsgFinal("Vous serez rappelé incessamment sous peu")
    }

    const verifiedPhone = async (code: string) => {
        if (code !== "6121")
            return;
        const _url = `${functionsendpoint}/verifyphonelpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ _id: id, verified: code === "6121" })
            });
            window.sessionStorage.clear();
            handleClose();
            setLeadcompleted(true);
        } catch (error) {
            console.log(error);
        }
    }

    const scrollToStep = (s: number) => {
        refs["rStep" + s]?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" });
        //scrollIntoView (refs["rStep" + s]?.current)
    }

    useEffect(() => {
        if (step > 1)
            scrollToStep(step);
        if (step === 12) {
            setLoader(true)
            setTimeout(() => setLoader(false), 4000)
        }
    }, [step]);

    useEffect(() => {
        if (id) {
            window.sessionStorage.setItem("id-recherche", id);
        }
    }, [id])

    const refs: Record<string, React.RefObject<HTMLDivElement>> = {
        rStep1: useRef<HTMLDivElement>(null),
        rStep2: useRef<HTMLDivElement>(null),
        rStep3: useRef<HTMLDivElement>(null),
        rStep4: useRef<HTMLDivElement>(null),
        rStep5: useRef<HTMLDivElement>(null),
        rStep6: useRef<HTMLDivElement>(null),
        rStep7: useRef<HTMLDivElement>(null),
        rStep8: useRef<HTMLDivElement>(null),
        rStep9: useRef<HTMLDivElement>(null),
        rStep10: useRef<HTMLDivElement>(null),
        rStep11: useRef<HTMLDivElement>(null),
        rStep12: useRef<HTMLDivElement>(null),
    }

    const isDateValid = () => {
        if (!data.datenaissance)
            return;
        if (data.datenaissance.length !== 10)
            return;
        const date = new Date(data.datenaissance);
        return date instanceof Date && !isNaN(date.getTime());
    }

    const getAgeFromDate = () => {
        if (!data.datenaissance)
            return 0;
        if (!data.datenaissance.match(/^\d{4}-\d{2}-\d{2}$/g))
            return 0;
        const today = new Date();
        const birthDate = new Date(data.datenaissance);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const cantContinueForm = () => {
        if (step >= 1) {
            if (!(data.origindemande.id)) return { step: 1, status: true, error: "Veuillez renseigner \"Pour qui effectuez-vous la demande ?\"" };
        }
        if (step >= 2) {
            if (!(data.domiciliation.id)) return { step: 2, status: true, error: "Veuillez renseigner \"Quelle est votre lieu de domiciliation ?\"" };
        }
        if (step >= 3) {
            if (!(data.domiciliation.id === 1 || data.regimeassurance.id)) return { step: 3, status: true, error: "Veuillez renseigner \"Quelle est votre régime d'assurance maladie ?\"" };
        }
        if (step >= 5) {
            if (!(data.sexe.id)) return { step: 5, status: true, error: "Veuillez renseigner \"Quelle est votre civilité ?\"" };
        }
        if (step >= 6) {
            if (!inputyearvalid (data.datenaissance)) return { step: 6, status: true, error: "Veuillez renseigner correctement \"Quelle est votre année de naissance ?\"" };
        }
        if (step >= 7) {
            if (!(data.occupation.id)) return { step: 7, status: true, error: "Veuillez renseigner \"Exercez-vous une activité lucrative ?\"" };
        }
        if (step >= 8) {
            if (!(data.occupation.id !== 2 || data.rechercheemploi.id)) return { step: 8, status: true, error: "Veuillez renseigner \"Êtes-vous à la recherche d'un emploi en Suisse ?\"" };
        }
        if (step >= 10) {
            if (!(data.prenom.length > 1 && data.nom.length > 1)) return { step: 10, status: true, error: "Veuillez renseigner votre nom ainsi que votre prénom" };
        }
        if (step >= 12) {
            if (!(data.telephone && isValidPhoneNumber(data.telephone as string))) return { step: 12, status: true, error: "Veuillez renseigner votre numéro de téléphone" };
        }
        return { step: 0, status: false, error: "" };
    }

    //Restore session on reload
    useEffect(() => {
        const _strstep = window.sessionStorage.getItem("step-recherche");
        if (_strstep) {
            const _step = parseInt(_strstep, 10);
            setStep(_step)
        }
        const _strdata = window.sessionStorage.getItem("data-recherche");
        if (_strdata) {
            const _data: ILead = JSON.parse(_strdata);
            setData(_data)
        }
        const _strid = window.sessionStorage.getItem("id-recherche");
        if (_strid) {
            setId(_strid)
        }
    }, []);

    if (loader) return (
        <DefaultTemplate showprogressbar={false} showcardinfo={false} dangeroushtmlcardinfo={step >= 12}>
            <Loader />
        </DefaultTemplate>
    )

    return (
        <DefaultTemplate
            step={step}
            maxstep={MAX_STEP}
            showModal={show}
            handleCloseModal={handleClose}
            phoneModal={data.telephone}
            sendSMSModal={sendSMS}
            verifiedPhoneModal={verifiedPhone}
            showprogressbar={step < 12}
            cardinfomsg={leadcompleted ? "Nous vous remercions de votre confiance dans la recherche de vos avoirs !" : `Pour des raisons de confidentialité, une vérification doit être effectué pour confirmer que nous avons bien affaire à <b>${data.nom} ${data.prenom}</b>`}
            cardinfophoto={Photo}
            showcardinfo={false}
            cardinfoposition={WITDH < 576 ? "bottom" : "top"}
            dangeroushtmlcardinfo={step >= 12}
        >
            {!leadcompleted && step >= 1 && step < 12 && <RechercheStep1 ref={refs.rStep1} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 2 && step < 12 && <RechercheStep2 ref={refs.rStep2} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 3 && step < 12 && data.domiciliation.id !== 1 && <RechercheStep3 ref={refs.rStep3} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 4 && step < 12 && <RechercheStep4 ref={refs.rStep4} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 5 && step < 12 && <RechercheStep5 ref={refs.rStep5} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 6 && step < 12 && <RechercheStep6 ref={refs.rStep6} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} isDateValid={isDateValid} getAgeFromDate={getAgeFromDate} />}
            {!leadcompleted && step >= 7 && step < 12 && <RechercheStep7 ref={refs.rStep7} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 8 && step < 12 && data.occupation.id === 2 && <RechercheStep8 ref={refs.rStep8} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 9 && step < 12 && <RechercheStep9 ref={refs.rStep9} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} nextStep={nextStep} />}
            {!leadcompleted && step >= 10 && step < 12 && <RechercheStep10 ref={refs.rStep10} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 11 && step < 12 && <RechercheStep11 ref={refs.rStep11} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} />}
            {!leadcompleted && step >= 12 && <RechercheStep12 ref={refs.rStep12} data={data} modifyData={modifyData} style={{ marginBottom: 40 }} nextStep={nextStep} />}
            {!leadcompleted && step < 12 &&
                <div className="continue-block">
                    <Button
                        fnClick={nextStep}
                        disabled={cantContinueForm().status}
                    >Continuer</Button>
                    {cantContinueForm().step < step && <p>{cantContinueForm().error}</p>}
                </div>
            }
            {leadcompleted &&
                <RechercheEnd
                    recallAsapLead={recallAsapLead}
                    msgFinal={msgFinal}
                    data={data}
                />
            }
        </DefaultTemplate>
    )
}

export default TunnelRecherche;