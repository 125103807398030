import React from "react";
import Cardinfo from "../../components/Cardinfo";
import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import Photo from "../../assets/portrait.png";
import DefaultTemplate from "../../template/default";

const EndView = () => {
    return (
        <DefaultTemplate>
            <Title style={{marginTop: 40, marginBottom: 40}}>Parfait ! Tout est bon.</Title>
            <Subtitle style={{marginTop: 40, marginBottom: 40}}>Votre demande à bien été bien été prise en compte, vous serez rapidement avisé du résultat.</Subtitle>
            <Cardinfo photo={Photo}>Un conseiller est en charge de votre dossier et prendra contact avec vous dans les prochains jours.</Cardinfo>
        </DefaultTemplate>
    )
}

export default EndView;