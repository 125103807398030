import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ILeadPlacement } from "../../../interfaces/lead";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Info from "../../../components/Info";
import Back from "../../../components/Back";
import Subtitle from "../../../components/Subtitle";
import RangeSlider from "../../../components/RangeSlider";
import Cardinfo from "../../../components/Cardinfo";
import {ImPlus, ImMinus} from "react-icons/im"
import { formatAmount, parseAmount } from "../../../utils/functions";
import Tile from "../../../components/Tile";

const QUESTION = "A combien s'élève le montant de vos avoirs ?";
const INFO = "Cette information nous est utile pour vous informer sur les possibités de rendement";

interface PropsPlacementStep {
    validateStep: (payload: ILeadPlacement) => void;
    data: ILeadPlacement;
    decrementStep?: () => void;
}

const PlacementStep3 : React.FC<PropsPlacementStep> = ({validateStep, data, decrementStep}) => {
    const [tileSelected, setTileSelected] = useState (data.montantlpp);

    const _onPress = () => {
        validateStep ({...data, montantlpp: tileSelected})
    }

    const [montant, setMontant] = useState (parseInt (data.montantlpp.title, 10) || 1000);
    const _onChangeAmount = (e : any) => {
        if (!parseAmount (e.target.value, "’").match (/^[0-9]*$/)) {
            return;
        }
        setMontant (parseInt (parseAmount (e.target.value, "’"), 10))
    }
    useEffect (() => {
        if (tileSelected.id === 5) {
            setTileSelected ({id: 5, title: montant.toString ()});
        }
    }, [montant])
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ stiffness: 150 }}
        >
            {decrementStep &&
                <Back fnClick={decrementStep} />
            }
            <div style={{display: "flex", marginBottom: 30}}>
                <Title style={{marginBottom: 0}}>{QUESTION}</Title>
                <Info title={INFO} tooltip={true} />
            </div>
            {/* <Subtitle>J'ai un montant d'environ {" "} 
                <input value={isNaN (montant) ? "" : formatAmount (montant, 'de-CH')} onChange={_onChangeAmount} style={{
                width: `max(${montant.toString ().length}ch * 1.4, 80px)`,
                textAlign: "center",
                borderColor: "#acbac8",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 5
            }} pattern="\d*"/> CHF.</Subtitle>
            <div style={{display: "flex", alignItems: "center"}} className="range-slider-lpp">
                <span className="helpers" style={{marginRight: 16, fontSize: "1.1rem", cursor: "pointer"}}
                    onClick={() => setMontant (montant - 1000)}
                ><ImMinus /></span>
                <RangeSlider
                    min={1000}
                    max={500000}
                    step={100}
                    size="lg"
                    value={isNaN (montant) ? 0 : montant}
                    onChange={(e) => setMontant (parseInt (e.target.value, 10))}
                    tooltipLabel={(v) => `${v} ${v===1 ? "CHF" : "CHF"}`}
                    style={{flex: 1}}
                    className="range-slider-lpp-range"
                    stripped
                />
                <span className="helpers" style={{marginLeft: 16, fontSize: "1.1rem", cursor: "pointer"}}
                    onClick={() => setMontant (montant + 1000)}
                ><ImPlus/></span>
            </div> */}
            <Tile 
                fnClick={() => setTileSelected ({id: 1, title: "Avoirs < 25’000CHF"})} 
                active={tileSelected.id === 1}
                style={{marginBottom: 20}}>
            Le montant de mes avoirs est inférieur à <b>25’000CHF</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 2, title: "Avoirs > 25’000CHF et < 50’000CHF"})} 
                active={tileSelected.id === 2}
                style={{marginBottom: 20}}>
            Le montant de mes avoirs est entre <b>25’000CHF</b> et <b>50’000CHF</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 3, title: "Avoirs > 50’000CHF et < 200’000CHF"})} 
                active={tileSelected.id === 3}
                style={{marginBottom: 20}}>
            Le montant de mes avoirs est entre <b>50’000CHF</b> et <b>200’000CHF</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 4, title: "Avoirs > 200’000CHF"})} 
                active={tileSelected.id === 4}
                style={{marginBottom: 20}}>
            Le montant des mes avoirs est supérieur à <b>200’000CHF</b></Tile>
            <Tile 
                fnClick={() => setTileSelected ({id: 5, title: montant.toString ()})} 
                active={tileSelected.id === 5}>
            Le montant des mes avoirs est égal à 
            <input value={isNaN (montant) ? "" : formatAmount (montant, 'de-CH')} onChange={_onChangeAmount} style={{
                width: `max(${montant.toString ().length}ch * 1.4, 80px)`,
                textAlign: "center",
                borderColor: "#acbac8",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 5,
                marginLeft: 10
            }} pattern="\d*"/> CHF</Tile>
            <Button style={{marginTop: 30}} fnClick={_onPress} disabled={tileSelected.id === 0}>Continuer</Button>
            <Cardinfo style={{marginTop: 40}} message={INFO+"."} />
        </motion.div>
    )
}

export default PlacementStep3;