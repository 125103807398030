import React from "react";
import Form from "react-bootstrap/Form";
import "./Input.css";

const InputText = React.forwardRef<any, any>(({valid = false, ...props}, ref) => {
    const identifier = '_' + Math.random().toString(36).substr(2, 9);

    return (
        <>
            {props.label &&
                <Form.Label htmlFor={identifier}>{props.label}</Form.Label>
            }
            <Form.Control
                {...props}
                ref={ref}
                id={identifier}
                className={`input-text ${valid ? "valid" : ""} ${props.error && props.error.length ? "error" : ""}`}
            />
            {props.error &&   
                <div className="input-err">
                    {props.error}
                </div>
            }
        </>
    )
});

export default InputText;