import React, { CSSProperties } from "react";
import "./Cardinfo.css";
import Portrait from "../../assets/portrait.png";

interface PropsCardinfo {
    photo?: string;
    message?: string;
    style?: CSSProperties;
    dangeroushtml?: boolean;
    action?: React.ReactNode;
}

const Cardinfo : React.FC<PropsCardinfo> = ({children, photo, message, style, dangeroushtml, action}) => {
    return (
        <div className="card-info" style={style}>
            <div>
                <img src={photo ?? Portrait} alt="conseiller" /> 
            </div>
            <div className="div-text">
                {dangeroushtml && message ?
                    <p dangerouslySetInnerHTML={{__html : message}}></p>
                    : <p>{message || children}</p>
                }
            </div>
            <div>
                {action}
            </div>
        </div>
    )
}

export default Cardinfo;