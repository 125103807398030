import React, { useEffect, useRef, useState } from "react";
import "./InputCode.css"

const InputCode = ({setCode} : {setCode: (code: string) => void}) => {

    const [d1s, setd1s] = useState ("");
    const [d2s, setd2s] = useState ("");
    const [d3s, setd3s] = useState ("");
    const [d4s, setd4s] = useState ("");

    const d1r = useRef<HTMLInputElement | null>  (null);
    const d2r = useRef<HTMLInputElement | null>  (null);
    const d3r = useRef<HTMLInputElement | null>  (null);
    const d4r = useRef<HTMLInputElement | null>  (null);

    useEffect (() => {
        d1r?.current?.focus ();
    }, [])

    useEffect (() => {
        setCode (`${d1s}${d2s}${d3s}${d4s}`)
    }, [d1s, d2s, d3s, d4s])

    const onChangeD1 = (e : React.FormEvent<HTMLInputElement>) => {
        const i = e.currentTarget.value;
        if (i.length && !/\d/.test (i))
            return;
        if (i.length > 1)
            return;
        if (i.length >= 1)
            d2r?.current?.focus ();
        setd1s (i)
    }
    const onChangeD2 = (e : React.FormEvent<HTMLInputElement>) => {
        const i = e.currentTarget.value;
        if (i.length && !/\d/.test (i))
            return;
        if (i.length > 1)
            return;
        if (i.length >= 1)
            d3r?.current?.focus ();
        if (d2s.length >= 1 && i.length === 0)
            d1r?.current?.focus ();
        setd2s (i)
    }
    const onChangeD3 = (e : React.FormEvent<HTMLInputElement>) => {
        const i = e.currentTarget.value;
        if (i.length && !/\d/.test (i))
            return;
        if (i.length > 1)
            return;
        if (i.length >= 1)
            d4r?.current?.focus ();
        if (d3s.length >= 1 && i.length === 0)
            d2r?.current?.focus ();
        setd3s (i);
    }
    const onChangeD4 = (e : React.FormEvent<HTMLInputElement>) => {
        const i = e.currentTarget.value;
        if (i.length && !/\d/.test (i))
            return;
        if (i.length > 1)
            return;
        if (d4s.length >= 1 && i.length === 0)
            d3r?.current?.focus ();
        setd4s (i);
    }

    return (
        <div className="d-flex flex-row justify-content-evenly">
            <input className="form-control" style={{width: 55, textAlign: "center"}} ref={d1r} value={d1s} onChange={onChangeD1} pattern="\d*" maxLength={1} size={1}/>
            <input className="form-control" style={{width: 55, textAlign: "center"}} ref={d2r} value={d2s} onChange={onChangeD2} pattern="\d*" maxLength={1} size={1}/>  
            <input className="form-control" style={{width: 55, textAlign: "center"}} ref={d3r} value={d3s} onChange={onChangeD3} pattern="\d*" maxLength={1} size={1}/>  
            <input className="form-control" style={{width: 55, textAlign: "center"}} ref={d4r} value={d4s} onChange={onChangeD4} pattern="\d*" maxLength={1} size={1}/>   
        </div>
    )
}

export default InputCode;