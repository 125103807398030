import {FaLongArrowAltLeft} from "react-icons/fa"
import React from "react";

import "./Back.css";

const Back = ({fnClick} : {fnClick: () => void}) => {
    return (
        <span className="back-icon" onClick={fnClick}>
            <FaLongArrowAltLeft />
        </span>
    )
}

export default Back;