import React, { useEffect, useState } from "react";
import { ILeadPlacement as ILead } from "../../interfaces/lead";
import DefaultTemplate from "../../template/default";
import { functionsendpoint } from "../../utils/endpoint";
import PlacementStep1 from "../../steps/TunnelPlacement/Step1";
import PlacementStep2 from "../../steps/TunnelPlacement/Step2";
import PlacementStep3 from "../../steps/TunnelPlacement/Step3";
import PlacementStep4 from "../../steps/TunnelPlacement/Step4";
import PlacementStep5 from "../../steps/TunnelPlacement/Step5";
import PlacementStep6 from "../../steps/TunnelPlacement/Step6";
import PlacementStep7 from "../../steps/TunnelPlacement/Step7";
import PlacementStep8 from "../../steps/TunnelPlacement/Step8";
import PlacementStep9 from "../../steps/TunnelPlacement/Step9";
import PlacementStep10 from "../../steps/TunnelPlacement/Step10";
import PlacementStep11 from "../../steps/TunnelPlacement/Step11";
import PlacementStep12 from "../../steps/TunnelPlacement/Step12";
import PlacementStep13 from "../../steps/TunnelPlacement/Step13";
import PlacementEnd from "../../steps/TunnelPlacement/End";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const MAX_STEP = 12;


const TunnelPlacement = () => {
    const sendDataToGTM = useGTMDispatch ();
    const [id, setId] = useState("");
    const [step, setStep] = useState(1);
    const [stepBlocked, setStepBlocked] = useState(1);
    const [phonestep, setPhonestep] = useState (false);
    const [data, setData] = useState<ILead>({
        caissepension: { id: 0, title: "" },
        connaissancemontantlpp: { id: 0, title: "" },
        montantlpp: {id: 0, title: ""},
        datenaissance: "",
        raisons: [],
        profilprevoyance: { id: 0, title: "" },
        occupation: { id: 0, title: "" },
        rechercheemploi: { id: 0, title: "" },
        nom: "",
        prenom: "",
        domiciliation: { id: 0, title: "" },
        regimeassurance: { id: 0, title: "" },
        telephone: undefined
    })
    const [leadcompleted, setLeadcompleted] = useState(false);
    const [msgFinal, setMsgFinal] = useState("");

    const validateStep = (payload: ILead) => {
        const newdata = payload;
        setData(newdata);
        window.sessionStorage.setItem("data-placement", JSON.stringify(newdata));

        let newstep = step + 1;
        if (step === 2 && newdata.connaissancemontantlpp.id === 2) {
            trackStep(newstep);
            newstep++;
        }
        if (step === 7 && newdata.occupation.id === 1) {
            trackStep(newstep);
            newstep++;
        }
        if (step === 10 && newdata.domiciliation.id === 1) {
            trackStep(newstep);
            newstep++;
        }
        if (step === 13) {
            setPhonestep (true);
            if (!phonestep) {
                sendDataToGTM({ event: 'leadOk' })
                trackStep(13);
            }
            return;
        }
        if (stepBlocked === step) {
            trackStep(step);
            setStepBlocked(newstep);
        }
        setStep(newstep);
        window.sessionStorage.setItem("step-placement", JSON.stringify(newstep));
    }

    const trackStep = async (s: number) => {
        const _url = `${functionsendpoint}/trackingsteplpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tunnel: "Placement", step: s.toString(), labelstep: getLabelStep(s) })

            });
        } catch (error) {
            console.log(error);
        }
    }

    const getLabelStep = (s: number) => {
        switch (s) {
            case 1:
                return "Caisse de pension"
            case 2:
                return "Connaisance montant des avoirs"
            case 3:
                return "Montant des avoirs (Skippable)"
            case 4:
                return "Date de naissance"
            case 5:
                return "Raisons placer avoirs (Facultatif)"
            case 6:
                return "Profil prévoyance"
            case 7:
                return "Exercice activité lucrative"
            case 8:
                return "Recherche un emploi en Suisse (Facultatif) (Skippable)"
            case 9:
                return "Nom prénom"
            case 10:
                return "Domiciliation"
            case 11:
                return "Regime assurance (Facultatif) (Skippable)"
            case 12:
                return "Loader"
            case 13:
                return "Téléphone"
            default:
                return "";
        }
    }

    const decrementStep = () => {
        let newstep = step - 1;
        if (newstep === 3 && data.connaissancemontantlpp.id === 2) {
            newstep--;
        }
        if (newstep === 8 && data.occupation.id === 1) {
            newstep--;
        }
        setStep(newstep);
    }

    const recallAsapLead = async () => {
        // const _url = "`${functionsendpoint}/recalllead`";
        // try {
        //     const response = await fetch (_url, {
        //         method: 'POST',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({_id: id})
        //     });
        //     const data = await response.json ();
        //     setMsgFinal (data.message);
        // } catch (error) {
        //     console.log (error);
        // }
        const _url = "https://comparea.ch/mailing/sms/recall.php?site=LPP&client=" + data.telephone?.replace("+", "");
        await fetch(_url);
        setMsgFinal("Vous serez rappelé incessamment sous peu")
    }


    const preSaveLead = async () => {
        if (!data.nom || !data.prenom)
            return;
        const _url = `${functionsendpoint}/addleadlpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data, type: "placement avoirs", ...(id && { _id: id }) })

            });
        } catch (error) {
            console.log(error);
        }
    }

    //Modal téléphone
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false);
    //
    const sendSMS = async () => {
        if (!data.telephone)
            return;
        const _url = `${functionsendpoint}/sendSMS?phone=${data.telephone.replace("+", "%2B")}`;
        try {
            await fetch(_url);
        } catch (error) {
            console.log(error)
        }
    }
    const verifiedPhone = async (code: string) => {
        if (code !== "6121")
            return;
        const _url = `${functionsendpoint}/verifyphonelpp`;
        try {
            await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ _id: id, verified: code === "6121" })
            });
            window.sessionStorage.clear();
            handleClose();
            setLeadcompleted(true);
        } catch (error) {
            console.log(error);
        }
    }

    const sendLead = async () => {
        if (!data.telephone)
            return;
        const _url = `${functionsendpoint}/addleadlpp`;
        try {
            const response = await fetch(_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...data, type: "placement avoirs", ...(id && { _id: id }) })

            });
            const result = await response.json();
            setId(result._doc._id);
        } catch (error) {
            console.log(error);
        }
    }

    const captureLead = () => {
        if (!data.telephone) return;
        sendLead();
        sendSMS();
        handleShow();
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <PlacementStep1
                        validateStep={validateStep}
                        data={data}
                    />
                )
            case 2:
                return (
                    <PlacementStep2
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 3:
                return (
                    <PlacementStep3
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 4:
                return (
                    <PlacementStep4
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 5:
                return (
                    <PlacementStep5
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 6:
                return (
                    <PlacementStep6
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 7:
                return (
                    <PlacementStep7
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 8:
                return (
                    <PlacementStep8
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 9:
                return (
                    <PlacementStep9
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 10:
                return (
                    <PlacementStep10
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 11:
                return (
                    <PlacementStep11
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 12:
                return (
                    <PlacementStep12
                        validateStep={validateStep}
                        data={data}
                        decrementStep={decrementStep}
                    />
                )
            case 13:
                return (
                    <PlacementStep13
                        validateStep={validateStep}
                        data={data}
                    />
                )
            case 14:
                return (
                    <PlacementEnd
                        msgFinal={msgFinal}
                        recallAsapLead={recallAsapLead}
                        data={data}
                    />
                )
            default:
                break;
        }
    }

    useEffect(() => {
        const _strstep = window.sessionStorage.getItem("step-placement");
        if (_strstep) {
            const _step = parseInt(_strstep, 10);
            setStep(_step)
        }
        const _strdata = window.sessionStorage.getItem("data-placement");
        if (_strdata) {
            const _data: ILead = JSON.parse(_strdata);
            setData(_data)
        }
        const _strid = window.sessionStorage.getItem("id-placement");
        if (_strid) {
            setId(_strid)
        }
    }, []);

    useEffect(() => {
        if (step === 10) {
            preSaveLead();
        }
        if (step >= 13) {
            captureLead();
        }
    }, [data])

    useEffect(() => {
        if (leadcompleted) {
            setStep(s => s + 1)
        }
    }, [leadcompleted]);

    useEffect(() => {
        if (id) {
            window.sessionStorage.setItem("id-placement", id);
        }
    }, [id])

    return (
        <DefaultTemplate
            step={step}
            maxstep={MAX_STEP}
            showModal={show}
            handleCloseModal={handleClose}
            phoneModal={data.telephone}
            sendSMSModal={sendSMS}
            verifiedPhoneModal={verifiedPhone}
        >
            {renderStep()}
        </DefaultTemplate>
    )
}

export default TunnelPlacement;